// CopyrightText.js

import React from 'react';
import './footer.css'; // Import the CSS file

const footer = () => {
  return (
    <div className="copyright-container p-60">
      <p className="copyright-text">
        &copy; 2023 <span> Powered by <a href='https://marixn.com/' target='_blank'> Marixn Technologies.</a></span> All rights reserved.
      </p>
    </div>
  );
};

export default footer;
