// AboutUsSection.js

import React from 'react';
import './About.css';
import Image1 from '../assets/Images/abt1.png';
import Image2 from '../assets/Images/abt2.png';
import Image3 from '../assets/Images/abt3.png';

const About = () => {
  return (
    <div className="about-us-section p-60">
      <div className="image-container">
        <img src={Image1} alt="Image 1" />
        <img src={Image2} alt="Image 2" />
        <img src={Image3} alt="Image 3" />
      </div>
      <div className="text-container">
        <h2>About Us</h2>
        <p>
        We're a media company currently in the process of creating a distinctive ecosystem for the media production sector.
        </p>
        <button className="slider-button">Oran Media</button>
      </div>
    </div>
  );
};

export default About;
