// TwoColumnLayout.js

import React from 'react';
import './Herosec.css'; // Import the CSS file
import Image from '../assets/Images/Hero-pic.png'

const Herosec = () => {
  return (
    <div className="two-column-container p60">
      <div className="column text-column">
        <h2>That sounds like an exciting initiative!</h2>
        <p>Creating a distinctive ecosystem for the media production sector involves integrating various elements and services to provide a comprehensive and seamless experience for users involved in media production.</p>
        <p><button className="slider-button">Oran Media</button></p>
      </div>
      <div className="column image-column">
        <img src={Image} alt="Your Image" className="responsive-image" />
      </div>
    </div>
  );
};

export default Herosec;
