import React, { useState } from 'react';
import './Navbar.css';
import logoImage from '../assets/Oranlogo.png'; 
import logoImage1 from '../assets/logo-oran-media.png'; 

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
      <div className="logo">
      <img src={logoImage1} alt="Logo" style={{ width: '60px', height: '60px', marginRight: '10px' }} />
      <span><img src={logoImage} alt="Logo" style={{ width: '200px', height: '60px', marginRight: '10px' }} /></span>
      </div>
      <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-items ${isMenuOpen ? 'open' : ''}`}>
        <li className='menuactive'><a href="mailto:connect@oranmedia.com">connect@oranmedia.com</a></li>
      </ul>
    </nav>
  );
}

export default NavBar;
