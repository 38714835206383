// HeroSlider.js

import React from 'react';
import './slider.css';
import SliderImage from '../assets/Images/slider.jpg'; 

const HeroSlider = () => {

    
  return (
    <div className="hero-slider p-60">
      <div className="slider-item">
      <img src={SliderImage} alt="Logo" />
        <div className="slider-content">
          <h1 className="main-title">We're forging a groundbreaking ecosystem in <span className="blink-move">stealth mode.</span></h1>
          <button className="slider-button">We Are Coming Soon</button>
        </div>
      </div>
    </div>

);
}

export default HeroSlider;
