// VideoPlayer.js
import React from 'react';
import './Video.css';

const Video = () => {
  const videoSource = require('../assets/Videos/1200 550.mp4');

  return (
    <div className="video-section">
    <video
      className="video-player"
      autoPlay
      loop
      playsInline
      muted   
    >
      <source src={videoSource} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    </div>
  );
};

export default Video;