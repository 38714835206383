import './App.css';
import Navbar from './components/Navbar';
import Slider from './components/slider';
import About from './components/About';
import Video from './components/Video';
import Herosec from './components/Herosec';
import Footer from './components/footer';

function App() {
  return (
    <div className="App">
    <Navbar />
    <Video />
    <About />
    <Slider />
    <Herosec />
    <Footer />
    </div>
  );
}

export default App;
